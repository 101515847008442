import { mdiVideoOutline, mdiDomain } from '@mdi/js';
export enum MeetingType {
  OFFICE = 'office',
  TELECONFERENCE = 'teleconference',
}

export const MeetingTypeDictionary = [
  {
    value: MeetingType.OFFICE,
    name: 'W biurze',
    icon: mdiDomain,
  },
  {
    value: MeetingType.TELECONFERENCE,
    name: 'Online',
    icon: mdiVideoOutline,
  },
];

